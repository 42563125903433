export const enum PaymentMethodsEnum {
  paymentMethod = "paymentMethod",
  paymentMethodsAvailable = "paymentMethodsAvailable",
  paymentPseUrl = "paymentPseUrl",
  preCreatePaymentMethod = "paymentMethods-PreCreate",
  paymentsAvailable = "paymentsAvailable",
  validateNequi = "validateNequi",
}

export const enum CreditsEnum {
  creditsAvailable = "creditsAvailable",
  referralCreditsAvailable = "referralCreditsAvailable",
}

export const enum productsDetailsEnum {
  getProductQuestions = "getProductQuestions",
  getProductReviews = "getProductReviews",
  getProductInfiniteReviews = "getProductInfiniteReviews",
  getProductStatistics = "getProductStatistics",
  getProductNutritional = "getProductNutritional",
}

export const enum CheckoutEnum {
  getCartAvailableProducts = "getCartAvailableProducts",
}

export const enum MembershipEnum {
  listMemebership = "listMemebership",
}

export const enum SearchBarEnum {
  searchResults = "searchResults",
  searchSuggests = "searchSuggests",
}

export const enum AddressEnum {
  deleteAddress = "deleteAddress",
  getAddresses = "getAddresses",
}

export const enum OrdersEnum {
  getOrdersList = "getOrdersList",
  getOrderDetails = "getOrderDetails",
  getOrderProducts = "getOrderProducts",
  getOrderProductsSKU = "getOrderProductsSKU",
  orderCancel = "orderCancel",
  orderReview = "orderReview",
}

export const enum IPEnum {
  ip = "ip",
}
